import React from "react";
import PropTypes from 'prop-types';
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';

import { optionalScrollTo } from '../../lib/helpers';
import Logo from '../../images/logo.png';

const Header = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { languages, originalPath } = useI18next();
    const languageNames = {
        'en': 'English',
        'es': 'Español',
    };

    return (
        <header>
            <div id="ucsf-banner-nav">
                <Container>
                    <ul className="menu clearfix">
                        <li className="first"><a href="http://www.ucsf.edu">University of California San Francisco</a></li>
                        <li><a href="http://www.ucsfhealth.org/">UCSF Medical Center</a></li>
                        <li><a href="http://www.ucsf.edu/search" title="">Search UCSF</a></li>
                        <li><a href="http://www.ucsf.edu/about">About UCSF</a></li>
                    </ul>
                </Container>
            </div>

            <Navbar variant="light" collapseOnSelect expand="md">
                <Container className="px-sm-3">
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={Logo} alt="Covidseeker" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-nav" />
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="ml-auto">
                            <Link to="/#team" className="nav-link" onClick={() => optionalScrollTo(location, '/#team')}>{t('team')}</Link>
                            <Link to="/#about" className="nav-link" onClick={() => optionalScrollTo(location, '/#about')}>{t('about')}</Link>
                            <Link to="/upload" className="nav-link">{t('donate')}</Link>
                            <a className="nav-link" href="https://www.ucsf.edu/website-privacy-policy">{t('privacy')}</a>
                        </Nav>
                    </Navbar.Collapse>

                    <ul className="language-switcher">
                        {languages.map(lng => (
                            <li key={lng}>
                                <Link to={originalPath} language={lng}>
                                    {languageNames[lng]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Container>
            </Navbar>
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
