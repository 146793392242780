import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Nav } from 'react-bootstrap';
import { useLocation } from '@reach/router';

import { optionalScrollTo } from '../../lib/helpers';

export default () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <footer>
            <Container>
                <Nav>
                    <Link to="/#team" className="nav-link" onClick={() => optionalScrollTo(location, '/#team')}>{t('team')}</Link>
                    <Link to="/#about" className="nav-link" onClick={() => optionalScrollTo(location, '/#about')}>{t('about')}</Link>
                    <Link to="/upload" className="nav-link">{t('donate')}</Link>
                    <a className="nav-link" href="https://www.ucsf.edu/website-privacy-policy">{t('privacy')}</a>
                </Nav>

                <p>Email us at <a href="mailto:Yulin.Hswen@ucsf.edu" className="font-weight-bold text-white">Yulin.Hswen@ucsf.edu</a></p>
                <p className="mt-1">&copy; {new Date().getFullYear()} COVIDSEEKER.</p>
            </Container>
        </footer>
    );
};