/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import MinimalLayout from './minimal';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <MinimalLayout>
            <div id="page-flex-wrapper">
                <Header siteTitle={data.site.siteMetadata.title} />
                {children}
                <Footer />
            </div>
        </MinimalLayout>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
